import React from 'react';
import styled from 'styled-components';
import {
  /* Icon, */ Theme,
  ThemeProvider,
  audiDarkTheme,
  audiLightTheme,
} from '@audi/audi-ui-react';

import { AppContent } from '../../types';
import { useTracking } from '../../utils/useTracking';
import { BasicTeaserLike } from './BasicTeaserLike';

const StyledContainer = styled.div`
  ${({ theme }: { theme: Theme }) => {
    return `
      background-color: var(${theme.colors.background.level[0]});
      padding: var(${theme.responsive.spacing.xxxl}) var(${theme.responsive.spacing.pageMargin});
    `;
  }}
`;

export const CountryTeaser: React.FC<AppContent> = (props) => {
  const { theme } = props;

  const ref = React.useRef(null);
  const tracking = useTracking(ref, props, {
    implementer: 37,
  });

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();
  }, []);

  return (
    <ThemeProvider theme={theme === 'Dark' ? audiDarkTheme : audiLightTheme}>
      <StyledContainer ref={ref} data-test-id="country-teaser">
        <BasicTeaserLike {...props} onTeaserClick={tracking.sendClickEvent} />
      </StyledContainer>
    </ThemeProvider>
  );
};

// <Icon name="smiley-happy" size="xs" /> Country Teaser
