import React from 'react';
import styled from 'styled-components';
import { Button, Text } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';

import { enableHTMLEntities } from '../../utils/enableHTMLEntities';
import { LegalInfo } from '../LegalInfo/LegalInfo';
import { AppContent } from '../../types';

const StyledImageContainer = styled.div`
  padding-bottom: 10px;

  img {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  ${(props) => {
    return `
      @media (min-width: ${props.theme.breakpoints.m}px) {
        padding-bottom: 0;
      }
    `;
  }}
`;

const StyledTextContainer = styled.div`
  ${(props) => {
    return `
      padding-top: var(${props.theme.responsive.spacing.m});

      @media (min-width: ${props.theme.breakpoints.m}px) {
        padding-top: 0;
        place-self: center start;
      }
    `;
  }}

  .headline > .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 13px !important;
  }

  .copy > .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 11px !important;
  }
`;

const StyledBasicTeaserLike = styled.div`
  cursor: pointer;
  pointer-events: all;

  a {
    cursor: pointer;
    pointer-events: all;
  }

  ${(props) => {
    return `
    .teaser-link {
      display: none;
      margin: var(${props.theme.responsive.spacing.xl}) 0;
    }

    @media (min-width: ${props.theme.breakpoints.m}px) {
        display: grid;
        grid-gap: var(${props.theme.responsive.spacing.xxl});
        grid-template-columns: repeat(2, 1fr);

        cursor: auto;
        pointer-events: none;

        .teaser-link {
          display: inline-flex;
          cursor: pointer;
          pointer-events: all;
        }
      }
    `;
  }}
`;

export interface BasicTeaserLikeProps extends AppContent {
  onTeaserClick(this: void): void;
}

export const BasicTeaserLike: React.FC<BasicTeaserLikeProps> = (props) => {
  const {
    image,
    imageAltText,
    headline,
    copy,
    linkLabel,
    linkUrl,
    consumptionAndEmissions,
    disclaimers,
    onTeaserClick,
  } = props;

  const handleButtonClickEvent = () => {
    onTeaserClick();
  };

  return (
    <StyledBasicTeaserLike>
      {image && (
        <StyledImageContainer>
          <UeElement type="media" property="image" label="Image">
            {(ueProps) => <img loading="lazy" src={image} alt={imageAltText || ''} {...ueProps} />}
          </UeElement>
        </StyledImageContainer>
      )}
      <StyledTextContainer>
        {headline && (
          <Text className="headline" variant="order3" spaceStackEnd="m">
            <UeElement type="text" property="headline" label="Headline">
              {renderTextWithFootnotesReferencesV2(enableHTMLEntities(headline))}
            </UeElement>
          </Text>
        )}
        {copy && (
          <Text className="copy" variant="copy1" spaceStackStart="s" spaceStackEnd="s">
            <UeElement type="text" property="copy" label="Copy">
              {renderTextWithFootnotesReferencesV2(enableHTMLEntities(copy))}
            </UeElement>
          </Text>
        )}
        <Button
          className="teaser-link"
          href={linkUrl}
          variant="secondary"
          onClick={handleButtonClickEvent}
        >
          <UeElement type="text" property="linkLabel" label="Link Label">
            {linkLabel}
          </UeElement>
        </Button>
        <LegalInfo consumptionAndEmissions={consumptionAndEmissions} disclaimers={disclaimers} />
      </StyledTextContainer>
    </StyledBasicTeaserLike>
  );
};
